import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 951.000000 954.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,954.000000) scale(0.100000,-0.100000)">
	<path d="M3356 6752 c-475 -2 -567 -4 -563 -16 3 -7 73 -102 156 -210 83 -109
151 -203 151 -210 0 -12 -28 -141 -131 -611 -11 -49 -24 -110 -29 -135 -5 -25
-48 -220 -96 -435 -47 -214 -90 -410 -95 -435 -5 -25 -13 -63 -18 -85 -19 -85
-73 -332 -81 -370 -5 -22 -30 -134 -55 -250 -62 -276 -67 -305 -61 -321 4 -12
69 -14 380 -14 425 0 384 -9 405 90 36 166 52 240 85 390 21 91 48 217 62 280
13 63 29 133 34 155 5 22 23 105 40 185 17 80 40 182 51 227 29 123 28 128
-44 204 -34 37 -116 124 -182 195 -66 71 -141 152 -167 180 -27 28 -45 54 -41
57 3 4 134 7 289 7 261 0 283 1 287 18 3 9 10 42 17 72 6 30 15 73 20 95 5 22
28 126 50 230 50 231 104 480 132 597 11 48 18 92 16 99 -5 16 54 14 -612 11z"/>
<path d="M4195 6750 c-4 -6 11 -34 33 -63 22 -29 92 -119 156 -202 126 -164
126 -164 101 -268 -8 -34 -33 -147 -55 -252 -45 -208 -49 -227 -81 -362 l-21
-93 -429 0 c-235 0 -430 -4 -433 -8 -3 -5 2 -18 12 -28 40 -45 228 -250 242
-264 8 -8 62 -67 120 -130 170 -187 145 -170 255 -170 109 0 106 4 81 -104 -8
-34 -49 -218 -91 -411 -42 -192 -85 -390 -96 -440 -11 -49 -24 -108 -29 -130
-5 -22 -14 -61 -21 -87 -19 -78 -20 -78 150 -78 82 0 152 4 155 9 3 4 26 102
50 217 25 115 71 324 101 464 31 140 74 336 96 435 22 99 53 241 69 315 30
140 56 256 185 846 l77 351 -173 226 -174 227 -122 0 c-67 0 -128 3 -136 6 -8
3 -18 0 -22 -6z"/>
<path d="M4630 6746 c0 -2 21 -30 46 -62 27 -35 52 -58 61 -56 9 2 12 -1 8 -8
-10 -17 42 -89 73 -102 16 -6 22 -12 14 -15 -9 -4 -5 -15 15 -41 15 -21 30
-39 33 -42 4 -3 15 -20 25 -37 11 -18 22 -33 26 -33 17 0 15 -46 -8 -141 -30
-133 -69 -310 -90 -419 -10 -47 -20 -87 -24 -90 -3 -3 -9 -29 -13 -59 -8 -62
-40 -210 -62 -291 -8 -30 -16 -71 -18 -90 -2 -19 -8 -53 -14 -75 -15 -54 -40
-160 -64 -275 -6 -30 -24 -113 -40 -185 -16 -71 -40 -182 -53 -245 -13 -63
-34 -153 -45 -200 -12 -47 -28 -119 -35 -160 -8 -41 -19 -97 -24 -124 -6 -26
-8 -52 -5 -57 3 -5 1 -9 -4 -9 -5 0 -12 -12 -15 -27 -3 -16 -12 -62 -21 -103
-8 -41 -17 -87 -21 -102 l-7 -28 858 1 c473 1 846 5 829 9 -29 7 -29 8 -5 10
14 1 27 2 30 2 3 0 4 36 2 80 -4 80 -4 80 20 73 49 -15 118 -48 118 -56 0 -5
11 -9 24 -9 14 0 28 -4 31 -10 3 -5 14 -10 24 -10 9 0 26 -8 37 -19 15 -13 25
-16 37 -9 28 15 20 25 -40 48 -32 13 -67 31 -79 42 -11 10 -29 18 -40 18 -29
1 -54 24 -54 52 0 13 -5 29 -12 36 -9 9 -9 12 0 12 8 0 12 20 12 59 0 58 0 59
32 65 22 4 36 2 43 -6 16 -20 52 -38 75 -38 11 0 28 -4 38 -9 9 -5 43 -21 75
-35 31 -14 57 -31 58 -38 1 -7 5 -2 9 12 l8 25 1 -25 c2 -42 17 -3 31 80 7 41
19 89 26 107 8 17 14 41 14 53 0 26 37 196 50 225 4 11 14 54 21 95 12 74 13
76 81 143 109 107 154 220 128 326 -12 50 -30 69 -30 32 0 -11 -4 -22 -9 -26
-5 -3 -12 -18 -16 -34 -3 -16 -22 -46 -41 -67 l-35 -39 4 41 c3 23 12 56 20
75 28 62 37 101 37 155 0 29 7 80 16 113 8 34 12 66 8 73 -4 7 -3 8 5 4 8 -5
10 11 7 61 -3 49 -2 57 4 28 5 -30 7 -33 8 -14 1 15 15 78 31 140 16 63 34
144 40 180 6 36 20 101 31 144 23 92 24 99 8 88 -7 -4 -5 5 4 19 10 14 27 70
39 125 11 54 25 114 29 133 26 105 62 281 67 323 4 38 1 58 -17 94 -13 25 -27
42 -34 40 -7 -3 -19 -1 -27 5 -11 8 -10 9 4 4 21 -7 22 7 1 25 -13 10 -17 10
-26 -2 -8 -11 -9 -11 -5 3 14 44 37 43 -1185 46 -637 1 -1158 1 -1158 -2z m83
-26 c-3 -12 -8 -19 -11 -16 -5 6 5 36 12 36 2 0 2 -9 -1 -20z m7 -61 c15 -28
5 -23 -15 7 -9 15 -12 23 -6 20 6 -4 16 -16 21 -27z m62 -68 c11 -11 19 -24
17 -29 -2 -5 -14 4 -27 20 -27 32 -21 37 10 9z m2286 -26 c-3 -3 -9 2 -12 12
-6 14 -5 15 5 6 7 -7 10 -15 7 -18z m-3 -55 c3 -5 1 -10 -4 -10 -6 0 -11 5
-11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-2185 -55 c0 -5 -5 -3 -10 5 -5 8 -10
20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z m60 -45 c-8 -5 -19 -10 -25 -10
-5 0 -3 5 5 10 8 5 20 10 25 10 6 0 3 -5 -5 -10z m14 -25 c-4 -8 -11 -15 -16
-15 -6 0 -5 6 2 15 7 8 14 15 16 15 2 0 1 -7 -2 -15z m2036 -100 c0 -23 3 -27
10 -15 5 8 10 11 10 5 0 -5 -4 -16 -9 -24 -10 -15 -14 -5 -26 59 -5 26 -3 31
4 20 6 -8 11 -28 11 -45z m-2050 -112 c-11 -17 -11 -17 -6 0 3 10 6 24 7 30 0
9 2 9 5 0 3 -7 0 -20 -6 -30z m1296 -39 c-3 -10 -16 -71 -30 -134 -15 -63 -28
-124 -31 -135 -8 -44 -46 -215 -65 -300 -18 -81 -39 -176 -55 -260 -4 -16 -17
-75 -30 -130 -21 -89 -45 -205 -58 -275 -2 -14 -13 -59 -25 -100 -11 -41 -23
-93 -26 -115 -4 -22 -9 -47 -12 -57 -4 -13 7 -10 58 14 35 16 97 45 138 65 41
19 91 44 110 55 19 10 55 29 80 40 24 11 50 27 58 34 7 8 22 14 33 14 10 0 19
4 19 9 0 5 13 13 28 19 16 5 35 17 44 25 15 15 31 21 54 18 16 -2 5 -92 -14
-113 -15 -18 -6 -23 22 -12 9 3 13 10 10 15 -3 5 1 9 9 9 25 0 7 -42 -34 -80
-40 -37 -154 -113 -229 -151 -25 -13 -47 -26 -50 -29 -3 -3 -36 -19 -75 -35
-38 -16 -72 -32 -75 -36 -3 -3 -15 -9 -28 -13 -12 -4 -19 -12 -16 -18 4 -6 2
-8 -4 -4 -11 7 -145 -51 -150 -65 -2 -5 -12 -9 -23 -9 -10 0 -19 -2 -19 -4 0
-2 -2 -11 -5 -19 -7 -16 135 48 147 67 5 8 8 7 8 -2 0 -9 19 -4 58 17 31 16
102 51 157 77 55 25 107 52 115 59 8 7 47 29 85 50 39 20 88 49 110 64 22 16
57 31 78 34 34 5 36 4 27 -13 -6 -10 -15 -22 -20 -25 -6 -3 -10 -28 -10 -55 0
-29 -5 -51 -12 -54 -10 -5 -10 -7 -1 -12 15 -7 5 -34 -13 -34 -8 0 -14 -5 -14
-10 0 -14 -51 -59 -67 -60 -7 0 -13 -4 -13 -10 0 -5 -7 -10 -15 -10 -18 0 -51
-18 -79 -42 -11 -10 -25 -18 -32 -18 -6 0 -23 -9 -37 -20 -14 -11 -32 -20 -41
-20 -9 0 -16 -4 -16 -10 0 -5 -6 -10 -12 -10 -7 -1 -66 -27 -130 -58 -65 -32
-118 -55 -118 -52 0 4 -15 14 -32 23 -44 23 -67 37 -103 66 -26 21 -30 22 -33
7 -2 -9 -7 -16 -12 -16 -16 0 -87 113 -97 155 l-10 40 -13 -30 c-8 -17 -13
-67 -14 -112 0 -71 -2 -83 -17 -83 -9 0 -20 7 -23 17 -6 15 -8 14 -20 -3 -13
-17 -28 -18 -182 -15 -107 1 -170 6 -173 13 -2 6 -7 5 -12 -3 -6 -9 -9 -2 -8
21 0 24 3 30 8 17 5 -13 10 -1 20 40 7 32 11 63 10 68 -1 6 1 17 4 25 5 14 40
156 51 212 15 74 58 273 71 333 9 39 23 102 30 140 8 39 25 117 39 175 34 134
40 173 39 235 -1 39 1 43 6 20 6 -27 8 -23 20 35 7 36 22 99 32 140 10 41 23
100 28 130 6 30 20 96 31 145 12 50 23 104 26 122 5 23 13 33 31 38 14 3 135
5 270 4 134 -1 247 2 250 5 10 10 23 -19 19 -40z m741 24 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1330 -20 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m-708 -58 c0 -10 -3 -8 -9 5 -5 12 -9 14 -9 6 -1 -8 -3
-12 -6 -9 -3 3 -2 13 1 22 5 14 9 15 15 4 5 -7 8 -20 8 -28z m701 2 c0 -7 -4
-10 -10 -7 -5 3 -10 16 -10 28 0 18 2 19 10 7 5 -8 10 -21 10 -28z m-33 -14
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m18 -48 c-4 -17 -9 -30
-11 -27 -2 2 -1 18 2 36 4 17 9 30 11 27 3 -2 2 -19 -2 -36z m-715 15 c0 -8
-4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m1321 -31 c-5
-21 -9 -30 -10 -19 -1 24 10 67 15 62 3 -2 0 -22 -5 -43z m0 -71 c-12 -20 -14
-14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m-1354 -45 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m1320 -40 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13
2 -7 2 -19 0 -25z m-1330 -30 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
-25z m1360 0 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m670 -65
c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-2025 -5 c-7 -7 -12
-8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m-18 -30 c6 -4 7 -10 3 -15
-10 -9 -27 16 -26 37 1 13 1 13 6 0 3 -8 11 -18 17 -22z m697 -29 c-5 -13 -10
-19 -10 -12 -1 15 10 45 15 40 3 -2 0 -15 -5 -28z m600 -50 c-5 -13 -10 -19
-10 -12 -1 15 10 45 15 40 3 -2 0 -15 -5 -28z m-614 -31 c-3 -7 -5 -2 -5 12 0
14 2 19 5 13 2 -7 2 -19 0 -25z m620 -30 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m670 -25 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0
-30z m30 15 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-10 -50
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-700 -45 c-3 -10 -5 -2
-5 17 0 19 2 27 5 18 2 -10 2 -26 0 -35z m680 15 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m-2040 -20 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7
2 -19 0 -25z m705 -60 c-8 -8 -9 -4 -5 13 4 13 8 18 11 10 2 -7 -1 -18 -6 -23z
m-726 -26 c-14 -15 -15 -13 -3 17 8 23 13 28 15 16 2 -10 -3 -25 -12 -33z
m701 26 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m0 -60 c-3 -7
-5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m20 10 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m-710 -50 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2
-7 2 -19 0 -25z m1290 -55 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0
-35z m-613 -37 c-3 -15 -8 -25 -11 -23 -2 3 -1 17 3 31 3 15 8 25 11 23 2 -3
1 -17 -3 -31z m606 -6 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
6 0 10 -4 10 -10z m677 -22 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0
-25z m-695 -50 c-7 -7 -12 -8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z
m25 -40 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-1360 -30 c0
-39 -2 -46 -8 -28 -4 14 -7 19 -8 11 -1 -8 -3 -12 -6 -10 -4 5 13 79 19 79 1
0 2 -24 3 -52z m1328 22 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10
10 2 0 7 -4 10 -10z m-628 -52 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z m-3 -56 c-6 -4 -14 -16 -17 -26 -4 -10 -10 -16 -13 -13 -9 8 15 47 29
47 8 0 9 -3 1 -8z m-728 -59 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15
-2 -18z m1311 -90 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z m-20
-25 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1340 -60 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1430 -14 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m-37 -8 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1
-10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-723 -38 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m753 18 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6
10 25 14 25 6z m-1423 -108 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28
0 -40z m690 10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-720
-20 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m690 -110 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1290 0 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m20 -25 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2
-21 0 -30z m-2040 -25 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m2008 -8 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z m32 -22 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1381 -85 c-10 -10 -19 5
-10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m1354 -10 c-11 -17 -11 -17 -6 0 3
10 6 24 7 30 0 9 2 9 5 0 3 -7 0 -20 -6 -30z m-1333 -15 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m-730 -70 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m30 0 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m2003 -62 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2
10 -4z m-16 -110 c-3 -15 -8 -25 -11 -23 -2 3 -1 17 3 31 3 15 8 25 11 23 2
-3 1 -17 -3 -31z m-2067 -98 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-20 -80 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M6104 3655 c26 -19 42 -19 26 0 -7 8 -20 15 -29 15 -13 -1 -13 -3 3
-15z"/>
<path d="M4030 3437 c-17 -9 -26 -22 -28 -45 -3 -31 -2 -32 35 -32 22 0 44 5
51 12 24 24 8 78 -22 78 -6 0 -22 -6 -36 -13z"/>
<path d="M4493 3440 c-48 -20 -39 -80 13 -80 51 0 79 56 40 82 -14 10 -25 9
-53 -2z"/>
<path d="M2370 3341 l-35 -6 -3 -72 c-3 -70 -4 -73 -27 -73 -24 0 -25 2 -25
70 l0 70 -32 0 c-18 0 -43 -3 -55 -6 -23 -6 -23 -9 -23 -175 l0 -169 24 0 c13
0 38 3 55 6 30 6 31 9 31 56 0 48 1 49 28 46 22 -2 27 -8 28 -33 1 -16 2 -36
3 -43 1 -9 15 -12 49 -10 l47 3 3 173 c3 191 9 177 -68 163z"/>
<path d="M2683 3343 c-22 -4 -23 -9 -23 -87 0 -104 -13 -146 -46 -146 -30 0
-44 47 -44 148 l0 72 -44 0 c-54 0 -56 -5 -56 -118 0 -106 13 -157 50 -194 23
-23 37 -28 77 -28 62 0 106 27 135 82 18 36 23 63 26 161 l4 117 -28 -1 c-16
-1 -39 -4 -51 -6z"/>
<path d="M3464 3305 c-22 -53 -85 -297 -80 -310 2 -6 25 -12 50 -14 36 -1 46
1 46 14 0 17 38 35 74 35 15 0 26 -7 29 -20 4 -16 11 -20 31 -15 14 3 37 7 51
10 16 3 25 12 25 24 0 16 -33 116 -90 279 -12 31 -14 32 -66 32 -53 0 -55 -1
-70 -35z m79 -157 c7 -26 1 -38 -20 -38 -10 0 -13 6 -9 16 3 9 6 22 6 30 0 22
17 16 23 -8z"/>
<path d="M3835 3329 c-49 -5 -96 -11 -102 -14 -10 -3 -13 -47 -13 -170 l0
-165 53 0 c28 0 82 3 120 7 l67 6 0 49 0 48 -57 -6 c-32 -4 -62 -7 -68 -8 -5
0 -10 6 -10 14 0 9 11 16 25 18 14 1 44 5 68 8 l43 5 -3 42 -3 42 -67 -4 c-55
-2 -68 0 -68 12 0 18 30 27 93 27 l48 0 -3 47 c-3 55 1 54 -123 42z"/>
<path d="M4165 3326 l-40 -12 -3 -167 -2 -167 54 0 55 0 3 80 3 79 55 -79 c30
-44 59 -79 63 -80 4 0 23 7 42 14 l35 15 0 160 0 161 -55 0 -55 0 0 -67 0 -66
-50 71 c-28 40 -54 72 -58 71 -4 0 -25 -6 -47 -13z"/>
<path d="M5065 3332 c-55 -5 -73 -12 -78 -29 -3 -10 -19 -69 -35 -131 -25 -90
-28 -113 -16 -108 9 3 12 1 9 -4 -3 -6 -14 -10 -23 -10 -12 0 -13 -3 -4 -12 7
-7 12 -17 12 -23 0 -5 -4 -4 -9 3 -7 11 -9 10 -14 -3 -4 -10 0 -18 10 -22 10
-4 19 -1 21 6 3 8 8 7 17 -5 16 -22 45 -12 45 16 0 14 7 20 23 21 12 1 35 3
51 5 21 2 29 -2 33 -17 6 -21 23 -23 70 -6 l31 12 -44 130 c-59 175 -62 181
-99 177z m25 -20 c0 -4 -9 -8 -20 -8 -11 0 -20 4 -20 8 0 4 9 8 20 8 11 0 20
-4 20 -8z m-63 -39 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z
m-13 -35 c6 -4 7 -10 3 -15 -10 -9 -27 16 -26 37 1 13 1 13 6 0 3 -8 11 -18
17 -22z m59 -118 c1 -9 -8 -16 -20 -18 -27 -4 -27 -1 -11 70 l12 53 9 -45 c4
-25 9 -52 10 -60z m24 53 c-3 -10 -5 -2 -5 17 0 19 2 27 5 18 2 -10 2 -26 0
-35z m48 -43 c-4 -12 -9 -19 -12 -17 -3 3 -2 15 2 27 4 12 9 19 12 17 3 -3 2
-15 -2 -27z m-158 -12 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m130 6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m53 -20 c0 -8
-4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m-205 -3 c3
-5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m55 -1 c0 -5 -5 -10 -11
-10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m17 -35 c0 -8 -4 -12 -9
-9 -4 3 -8 9 -8 15 0 5 4 9 8 9 5 0 9 -7 9 -15z m118 5 c3 -5 1 -10 -4 -10 -6
0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m22 -26 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m-195 -29 c-2 -14 -8 -25 -12 -25 -4 0 -5 11
-2 25 2 14 8 25 12 25 4 0 5 -11 2 -25z m165 9 c-3 -3 -12 -4 -19 -1 -8 3 -5
6 6 6 11 1 17 -2 13 -5z"/>
<path d="M5395 3335 c-5 -2 -40 -6 -77 -10 l-68 -7 0 -39 c0 -41 0 -41 64 -43
l29 -1 1 -115 c0 -109 5 -130 31 -130 6 0 3 5 -5 11 -11 7 -3 8 30 4 33 -4 41
-3 30 4 -13 10 -13 11 0 12 8 1 2 5 -15 9 l-30 8 28 1 27 1 0 100 c0 108 -2
104 61 114 35 6 37 8 35 46 l-1 40 -65 -1 c-36 0 -69 -2 -75 -4z m-28 -21 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m121 -1 c-21 -2 -55 -2 -75
0 -21 2 -4 4 37 4 41 0 58 -2 38 -4z m-31 -45 c-3 -7 -5 -2 -5 12 0 14 2 19 5
13 2 -7 2 -19 0 -25z m-182 13 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6
21 15 7z m32 -27 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M5701 3331 c-35 -6 -31 1 -74 -136 -11 -34 -12 -52 -5 -56 7 -4 5
-10 -5 -15 -8 -5 -19 -29 -24 -54 -6 -25 -12 -52 -14 -60 -3 -9 3 -14 14 -14
9 1 17 -3 17 -10 0 -6 5 -4 10 4 6 10 10 11 10 3 0 -23 38 -15 42 8 2 17 14
25 51 34 46 12 48 11 53 -11 7 -27 13 -28 66 -14 36 10 36 11 27 43 -6 17 -19
59 -29 92 -28 91 -63 179 -75 187 -11 8 -14 8 -64 -1z m66 -31 c3 -10 2 -11
-4 -4 -4 7 -19 14 -33 17 -22 4 -21 5 4 4 17 -1 31 -9 33 -17z m-100 -65 c-1
-21 -4 -30 -9 -22 -5 8 -8 3 -8 -12 0 -13 -3 -21 -7 -18 -5 6 16 87 23 87 1 0
2 -16 1 -35z m120 -67 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m43 -22 c0 -18 -17 8 -24 34 l-7 25 15 -25 c9 -14 15 -29 16 -34z m-92 24 c7
-12 10 -26 6 -32 -4 -7 -3 -8 4 -4 7 4 12 3 12 -3 0 -26 -53 -37 -71 -14 -8 9
-9 14 -3 10 8 -4 14 6 18 28 8 40 16 44 34 15z m-61 -22 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m18 -98 c13 -6 15 -9 5 -9 -8 0 -22 4 -30 9
-18 12 -2 12 25 0z m-68 -32 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z"/>
<path d="M6413 3333 c-9 -3 -13 -32 -13 -97 0 -99 -14 -136 -51 -136 -27 0
-39 38 -46 141 l-6 86 -36 -4 c-19 -2 -38 -6 -41 -9 -3 -3 -6 -45 -7 -93 -3
-105 11 -160 41 -162 18 -1 19 -2 3 -6 -16 -4 -14 -9 14 -34 35 -31 74 -37
112 -18 28 14 76 59 88 81 15 27 31 178 24 220 -6 35 -9 38 -38 37 -18 0 -38
-3 -44 -6z m72 -33 c3 -6 -1 -7 -9 -4 -18 7 -21 14 -7 14 6 0 13 -4 16 -10z
m-198 -107 c-2 -21 -4 -4 -4 37 0 41 2 58 4 38 2 -21 2 -55 0 -75z m-50 47
c-1 -16 -5 -30 -9 -30 -9 0 -10 8 -2 38 9 31 14 28 11 -8z m33 -24 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m147 -98 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m50 -10 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-62 -7 c3 -5 1 -12 -5 -16 -5 -3 -10 1
-10 9 0 18 6 21 15 7z m-59 -40 c-4 -5 0 -11 6 -13 8 -3 5 -9 -9 -16 -16 -9
-24 -9 -29 0 -4 6 -3 8 4 4 6 -3 13 -2 17 4 3 5 0 10 -7 10 -9 0 -9 3 2 10 19
12 23 12 16 1z m64 -21 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19 12 20 12
20 0z m-33 -26 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M6570 3329 c-13 -6 -28 -15 -32 -22 -5 -7 -8 -78 -7 -159 2 -170 -3
-164 110 -148 65 9 92 19 139 52 35 25 34 83 -3 112 l-27 21 20 33 c27 44 25
60 -10 93 -27 26 -36 29 -97 28 -38 0 -79 -5 -93 -10z m-13 -41 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m93 1 c0 -5 -4 -9 -10 -9 -5 0 -10 7
-10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m68 -35 c-4 -3 -12 5 -18 18 -12 22
-12 22 6 6 10 -10 15 -20 12 -24z m29 -1 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18
2 -7 2 -21 0 -30z m-180 -5 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m118 1 c8 -24 -8 -39 -42 -39 -13 0 -26 0 -30 0 -9 0 -13 41 -6 53 2 4
19 7 38 7 26 0 35 -5 40 -21z m-95 -49 c0 -4 -6 -10 -14 -13 -10 -4 -12 0 -9
14 5 18 23 18 23 -1z m-43 -42 c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2
-28 0 -40z m128 32 c-3 -5 -16 -10 -28 -9 -21 0 -21 1 -2 9 28 12 37 12 30 0z
m50 0 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m-115 -20 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m87 -6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-8 -30
c7 -2 21 1 29 8 12 10 11 4 -4 -20 -16 -29 -63 -66 -75 -59 -2 1 2 -3 9 -9 10
-9 10 -13 -3 -18 -9 -3 -14 -2 -11 3 3 4 -3 16 -14 26 -16 15 -19 29 -11 77 1
7 47 2 80 -8z m11 -74 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10
6 0 11 -4 11 -10z m50 -10 c-8 -5 -19 -10 -25 -10 -5 0 -3 5 5 10 8 5 20 10
25 10 6 0 3 -5 -5 -10z m-175 -38 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3
15 -9 12 -12z m112 12 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M2849 3307 c-41 -28 -59 -60 -59 -109 0 -56 33 -89 95 -95 75 -7 84
-11 60 -29 -15 -11 -35 -13 -81 -8 l-61 6 -6 -38 c-4 -21 -2 -43 3 -48 16 -16
162 -19 189 -4 66 36 89 132 42 182 -19 20 -35 26 -80 28 -42 2 -56 7 -56 18
0 12 14 15 68 15 43 0 67 4 67 11 0 6 3 25 6 42 6 29 4 31 -32 41 -63 18 -117
13 -155 -12z"/>
<path d="M3152 3313 c-46 -22 -65 -56 -66 -114 -1 -66 23 -91 99 -97 30 -2 58
-8 60 -13 10 -16 -35 -29 -91 -27 l-58 3 -7 -35 c-5 -20 -4 -39 2 -45 6 -5 50
-10 99 -10 87 0 90 1 121 32 25 24 33 42 36 80 4 43 2 51 -24 75 -22 21 -40
27 -83 30 -41 2 -55 7 -55 18 0 12 14 15 67 15 65 0 67 1 72 29 10 47 7 54
-25 65 -46 16 -109 13 -147 -6z"/>
<path d="M4018 3323 l-28 -4 0 -170 0 -169 24 0 c13 0 38 3 55 6 l31 7 0 168
0 169 -27 -1 c-16 -1 -40 -4 -55 -6z"/>
<path d="M4473 3323 c-23 -4 -23 -6 -23 -174 l0 -169 24 0 c13 0 38 3 55 6
l31 7 0 168 0 169 -32 -1 c-18 -1 -43 -4 -55 -6z"/>
<path d="M4642 3320 c-18 -4 -35 -10 -37 -12 -20 -20 -14 -325 6 -312 5 3 9 0
9 -5 0 -17 47 -13 116 11 85 29 113 67 121 165 5 57 2 76 -12 101 -32 54 -110
74 -203 52z m91 -7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-6
-19 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-37 -14 c20 -6 21
-8 5 -8 -11 0 -29 3 -40 8 -25 11 1 11 35 0z m77 -6 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m52 -46 c1 -5 -7 0 -17 10 -12 11 -14 19 -8 24
10 6 23 -13 25 -34z m-149 22 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10
11 10 2 0 4 -4 4 -10z m67 4 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m31 -42 c17 -22 15 -87 -3 -105 -17 -18 -73 -32 -83 -22 -11 10 -8 139
3 147 14 10 69 -3 83 -20z m32 3 c0 -8 -2 -15 -4 -15 -2 0 -6 7 -10 15 -3 8
-1 15 4 15 6 0 10 -7 10 -15z m-183 -77 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2
-7 2 -19 0 -25z m230 10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-37 -38 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m21 -17 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m-167
-19 c8 -21 8 -24 -3 -24 -5 0 -11 9 -14 20 -6 24 8 27 17 4z m-47 -36 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m173 12 c8 -5 11 -10 5 -10 -5
0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z m-90 -15 c11 -13 10 -14
-4 -9 -9 3 -16 10 -16 15 0 13 6 11 20 -6z m49 6 c7 -5 9 -11 5 -14 -5 -3 -15
1 -22 9 -14 16 -5 18 17 5z"/>
<path d="M5995 3310 c-58 -19 -95 -117 -61 -162 20 -27 67 -47 95 -42 32 6 56
-9 48 -30 -4 -10 -20 -17 -44 -18 -37 -1 -45 -1 -79 5 -23 3 -40 -48 -24 -68
14 -17 55 -21 45 -5 -3 6 -1 10 4 10 6 0 11 -7 11 -16 0 -26 100 -15 130 14
13 12 27 22 31 22 3 0 12 14 19 31 30 71 -31 147 -105 133 -43 -8 -69 9 -55
36 10 20 79 33 81 15 1 -5 5 1 9 15 8 24 8 24 9 3 1 -33 37 -31 43 2 4 18 1
25 -11 26 -12 0 -12 2 3 8 12 5 16 10 9 14 -20 12 -127 17 -158 7z m80 -10
c-11 -5 -27 -9 -35 -9 -9 0 -8 4 5 9 11 5 27 9 35 9 9 0 8 -4 -5 -9z m-95 -35
c-7 -8 -18 -15 -24 -15 -6 0 -3 7 7 14 9 7 17 19 18 27 0 11 2 11 6 1 3 -7 -1
-19 -7 -27z m100 15 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0
4 -4 4 -10z m-75 -30 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0
8 -4 11 -10z m-36 -104 c-2 -2 -13 8 -23 22 l-19 27 24 -22 c12 -12 21 -24 18
-27z m32 27 c13 -15 12 -15 -8 -4 -24 12 -29 21 -14 21 5 0 15 -7 22 -17z
m125 -18 c10 -8 14 -15 8 -15 -5 0 -19 7 -30 15 -10 8 -14 15 -8 15 5 0 19 -7
30 -15z m-125 -14 c-11 -7 -9 -10 10 -14 23 -4 22 -4 -3 -6 -27 -1 -35 8 -21
22 3 4 11 7 17 7 6 0 5 -4 -3 -9z m-44 -103 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
